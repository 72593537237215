<template>
  <div>
    <div class="edit_box  mrb20">
      <div class="size16">
        筛选查询
      </div>
      <div class="mrt20">
        <el-form ref="form" :model="selectForm" label-width="80px" class="flex">
          <el-form-item label="一级分类">
            <el-input v-model="selectForm.name" placeholder="请输入分类名称" style="width: 200px;" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="checkBoxFn(Object.assign(selectForm,pageInfo))">查找</el-button>
            <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="stairVisible = true">新增一级分类</el-button>
            <el-button icon="el-icon-plus" style="margin-left: 20px;" @click="secondVisible = true">新增二级分类</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="mrb20 bg-white pd20">
      <div class="mrb20">数据列表</div>
      <div>
        <el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" row-key="id" border default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column prop="id" label="ID">
          </el-table-column>
          <el-table-column prop="name" label="分类名称">
          </el-table-column>
          <el-table-column prop="img" label="分类图片">
            <template slot-scope="scope">
              <el-image :preview-src-list="[scope.row.img]" style="width: 100px; height: 100px" :src="scope.row.img" fit="fill"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="是否启用">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status == 1" type="success" plain size="mini">启用</el-button>
              <el-button type="info" plain size="mini" v-if="scope.row.status == 0">禁用</el-button>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-edit" type="primary" @click="edit(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="handleDelete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="mrt20 flex justify-end ">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
          </el-pagination>
        </div>

      </div>
    </div>

    <!-- 新增一级分类 -->
    <el-dialog title="一级分类" :visible.sync="stairVisible" width="40%">
      <el-form ref="originaForm" :model="originaForm" label-width="100px">
        <el-form-item label="行页分类">
          <el-select v-model="originaForm.pid" placeholder="选择行页分类" clearable>
            <el-option v-for="(item,i) in categoryData" :key="i" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称" required>
          <el-input v-model="originaForm.name"></el-input>
        </el-form-item>
        <el-form-item label="分类图片">
          <file-pic :file='originaForm.img' @input='originaForm.img = $event'></file-pic>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="originaForm.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="stairVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit(originaForm)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增二级分类 -->
    <el-dialog title="二级分类" :visible.sync="secondVisible" width="40%">
      <el-form ref="secondForm" :model="secondForm" label-width="100px">
        <el-form-item label="二级名称" required>
          <el-input v-model="secondForm.name"></el-input>
        </el-form-item>
        <el-form-item label="一级分类">
          <el-select v-model="secondForm.pid" placeholder="请选择一级分类名称" clearable>
            <el-option v-for="(item,i) in tableData" :key="i" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类图片">
          <file-pic :file='secondForm.img' @input='secondForm.img = $event'></file-pic>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="secondForm.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="secondVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit(secondForm)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑一级分类 -->
    <el-dialog title="编辑分类" :visible.sync="editStairVisible" width="40%">
      <el-form ref="editForm" :model="editForm" label-width="100px">
        <el-form-item label="分类名称" required>
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="分类图片">
          <file-pic :file='editForm.img' @input='editForm.img = $event'></file-pic>
        </el-form-item>
        <el-form-item label="一级分类" v-if="level == 2">
          <el-select v-model="editForm.pid" placeholder="请选择一级分类名称" clearable>
            <el-option v-for="(item,i) in tableData" :key="i" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="editForm.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in statusDetail" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editStairVisible = false">取 消</el-button>
        <el-button type="primary" @click="editOnSubmit(editForm)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import filePic from "@/components/common/filePic";
import { statusOption, statusDetail } from "@/utils/statusData";
export default {
  components: { filePic },
  props: {},
  data() {
    return {
      statusOption,
      statusDetail,
      stairVisible: false,
      secondVisible: false,
      editStairVisible: false,
      level: "", //1一级分类 2 二级分类
      categoryData: "",
      pageInfo: {
        limit: 10,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      selectForm: {
        name: "",
      },
      // 新增
      originaForm: {
        id: "",
        pid: "",
        name: "",
        img: "",
        status: "",
      },
      secondForm: {
        id: "",
        pid: "",
        name: "",
        img: "",
        status: "",
      },
      editForm: {
        id: "",
        // pid: "",
        name: "",
        img: "",
        status: "",
      },
      tableData: [],
      evaluatePictureList: "",
    };
  },

  created() {
    this.getBrand();
    this.getList(this.pageInfo);
  },
  mounted() {},
  computed: {},

  methods: {
    // 行页
    async getBrand() {
      const { data } = await this.$Api.categoryCategoryFirst();
      console.log("行页分类", data);
      this.categoryData = data.list;
    },
    edit(row) {
      console.log("要编辑的东西", row);
      this.level = row.level;
      this.editForm.id = row.id;
      switch (row.level) {
        case 1:
          this.editForm = { ...row };
          break;
        case 2:
          this.editForm = { ...row };
      }
      this.editStairVisible = true;
    },
    // 新增
    async onSubmit(row) {
      const data = await this.$Api.categoryAdd(row);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("新增成功");
      this.stairVisible = false;
      this.secondVisible = false;
      this.originaForm = {
        id: "",
        pid: "",
        name: "",
        img: "",
        status: "",
      };
      this.secondForm = {
        id: "",
        pid: "",
        name: "",
        img: "",
        status: "",
      };
      this.getList(this.pageInfo);
    },
    // 编辑
    async editOnSubmit(row) {
      const data = await this.$Api.categoryEdit(row);
      if (data.code !== 200) {
        this.$message.error(data.msg);
        return;
      }
      this.$message.success("编辑成功");
      this.editStairVisible = false;
      this.getList(this.pageInfo);
    },
    async getList(pageInfo) {
      // console.log("this.originaForm.status", pageInfo);
      // return;
      const { data } = await this.$Api.categoryCategoryAllList(pageInfo);
      this.tableData = data.list;
      console.log("体育分类列表", data);
      this.pageInfo.limit = data.limit;
      this.pageInfo.page = data.page;
      this.pageInfo.count = data.count;
      this.pageInfo.pageNum = data.pageNum;
      // Object.assign(this.pageInfo, data);
      // console.log(data, "成功的原著列表");
    },
    handleSizeChange(val) {
      // console.log("val", val);
      this.pageInfo.limit = val;
      this.pageInfo.page = 1;
      this.getList(this.pageInfo);
    },
    handleCurrentChange(val) {
      // console.log("val", val);
      this.pageInfo.page = val;
      this.getList(this.pageInfo);
    },
    // 批量删除
    // batchdelete() {
    //   this.$confirm("确定要删除该商品信息吗?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       console.log("点击删除");
    //       return;
    //       this.$message({
    //         type: "success",
    //         message: "删除成功!",
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    // 单个删除
    handleDelete(row) {
      console.log("获取删除id", row);
      this.$confirm("确定要删除该商品信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$Api.categoryDel({ id: row });
          // console.log("删除成功", data);
          if (data.code != 200) {
            return this.$message({
              message: data.msg,
              type: "error",
            });
          }
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.getList(this.pageInfo);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: data.msg,
          });
        });
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-tooltip__popper.is-dark {
  width: 700px !important;
}
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
</style>